var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loginBg", attrs: { id: "login" } }, [
    _c("div", { staticClass: "login-title" }),
    _c("div", { staticClass: "loginware" }, [
      _c("div", { staticClass: "login-box" }, [
        _c("div", { staticClass: "loginboard" }, [
          _c(
            "div",
            { staticClass: "loginboard-form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "0",
                  },
                },
                [
                  _c("div", { staticClass: "login-name" }, [
                    _vm._v("申请使用"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "companyName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入公司名称或股票代码" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitForm("ruleForm")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "companyName", $$v)
                          },
                          expression: "ruleForm.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "applyName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitForm("ruleForm")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.applyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "applyName", $$v)
                          },
                          expression: "ruleForm.applyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "officeMailbox" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入办公邮箱" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitForm("ruleForm")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.officeMailbox,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "officeMailbox", $$v)
                          },
                          expression: "ruleForm.officeMailbox",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "phoneNumber" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitForm("ruleForm")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.phoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phoneNumber", $$v)
                          },
                          expression: "ruleForm.phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "position" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入职务" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitForm("ruleForm")
                          },
                        },
                        model: {
                          value: _vm.ruleForm.position,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "position", $$v)
                          },
                          expression: "ruleForm.position",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "subbtn",
                          attrs: { type: "primary", onkeydown: "myFunction()" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("立即申请 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }