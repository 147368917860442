<template>
  <div id="login" class="loginBg">
    <div class="login-title"></div>
    <div class="loginware">
      <div class="login-box">
        <div class="loginboard">
          <div class="loginboard-form">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="0"
            >
              <div class="login-name">申请使用</div>
              <el-form-item label="" prop="companyName">
                <el-input
                  v-model="ruleForm.companyName"
                  placeholder="请输入公司名称或股票代码"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="applyName">
                <el-input
                  v-model="ruleForm.applyName"
                  placeholder="请输入姓名"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="officeMailbox">
                <el-input
                  v-model="ruleForm.officeMailbox"
                  placeholder="请输入办公邮箱"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="phoneNumber">
                <el-input
                  v-model="ruleForm.phoneNumber"
                  placeholder="请输入手机号"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="position">
                <el-input
                  v-model="ruleForm.position"
                  placeholder="请输入职务"
                  @keyup.enter.native="submitForm('ruleForm')"
                ></el-input>
              </el-form-item>
              <el-form-item label-width="0" class="">
                <el-button
                  type="primary"
                  class="subbtn"
                  @click="submitForm('ruleForm')"
                  @keyup.enter.native="submitForm('ruleForm')"
                  onkeydown="myFunction()"
                  >立即申请
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- <div class="login-right"> -->
        <!-- <img src="../../assets/logo-vertical-white.png" alt="支出宝" /> -->
        <!-- 申请试用 -->
        <!-- </div> -->
      </div>
    </div>
    <!-- <div class="addr">
      <p>公司地址：北京市东城区朝阳门银河soho C座7层30706室</p>
      <div class="comp">
        <p>Copyright 2016-2022 支出宝（北京）信息技术有限公司</p>
        <p>京ICP备 16061542号-11 京公网安备 11010502040802号</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { applyAddApi } from '@/api/tryuse/index'
export default {
  data() {
    let checkname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号/手机号/邮箱'))
      } else if (/[\u4E00-\u9FA5]/g.test(value)) {
        callback(new Error('格式错误!'))
      } else {
        callback()
      }
    }
    let checkpassword = (rule, value, callback) => {
      if (value === '') {
        callback('密码不能为空')
      } else {
        callback()
      }
    }
    return {
      identifyCode: 'abcd',
      ruleForm: {
        applyName: '',
        companyName: '',
        officeMailbox: '',
        phoneNumber: '',
        position: ''
      },
      rules: {
        applyName: [
          { required: true, message: '请输入申请人名称' },
          { validator: checkname, trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入企业名称' },
          { validator: checkpassword, trigger: 'blur' }
        ],
        officeMailbox: [{ required: true, message: '请输入办公邮箱' }],
        phoneNumber: [{ required: true, message: '请输入手机号' }],
        position: [{ required: true, message: '请输入职位' }]
      }
    }
  },
  created() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          applyAddApi({ ...this.ruleForm }).then(res => {
            console.log(res)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.loginBg {
  position: relative;
  min-height: 100vh;
  background: url('../../assets/images/bg5.jpg') no-repeat;
  background-size: 100%;
  background-size: cover;
  user-select: none;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.22);
  // }
}
.login-right {
  width: 350px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  color: beige;
  background: rgba(255, 255, 255, 0.22);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 28px;
  img {
    height: 216px;
  }
}
.loginware {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
  min-height: 640px;
  opacity: 0.8;
}
.login-box {
  width: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 540px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.login-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 128px;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 100px;
  font-weight: 500;
  color: #ffffff;
  img {
    width: 145px;
    margin-right: 20px;
  }
}
.loginboard {
  // width: 90%;
  // max-width: 350px;

  .subbtn {
    margin-top: 30px;
    width: 100%;
    font-size: 16px;
    letter-spacing: 2px;
    // background-color: $themeColorBtn;
    // border-color: $themeColorBtn;
    border-color: #ffffff;
    background: transparent;
  }
}
.loginboard-title {
  padding-bottom: 40px;
  font-size: 24px;
  color: #fff;
  img {
    display: inline-block;
    margin-right: 16px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 20px;
  }
}
.loginboard-form {
  width: 500px;
  box-sizing: border-box;
  padding: 10px 30px 20px;
  background: rgba(45, 52, 54, 1);
  height: 520px;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /deep/.el-form{
    width: 80%;
  }
}
.login-name {
  text-align: center;
  font-weight: bold;
  margin: 0;
  color: beige;
  font-size: 30px;
  margin-bottom: 40px;
}
.identifyBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 43px;
  .valid {
    position: relative;
    margin-top: 1px;
    margin-left: 5px;
  }
}
.addr {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  p {
    padding: 0 0 10px;
    margin: 0;
  }
  .comp {
    width: 650px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 20px;
    p {
      line-height: 1;
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  -webkit-text-fill-color: #fff; /* 设置字体颜色 */
  font-size: 18px;
  &::placeholder {
    background: transparent;
    color: #fff !important;
    font-size: 15px;
  }
  &:-webkit-autofill {
    background: transparent;
    color: #fff !important;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
  }
}
</style>
