import apiServer from 'api/apiServer.js'

// 企业和企业用户新增
export function applyAddApi(params = {}) {
  let url = `/white/trial/apply/add` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 列表
export function applyPageApi(params = {}) {
  let url = `/trial/apply/page` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 删除
export function handleTrialApplyApi(params = {}) {
  let url = `/trial/apply/handleTrialApply` //post methods
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}


// 试用表单导出
export function applyExportApi(params = {}) {
  return apiServer
    .downfileStream("/trial/apply/export", params)
    .then((res) => {
      return Promise.resolve(res);
    });
}